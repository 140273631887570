import { motion } from "framer-motion"
import { ReactNode } from "react"
import "./ContactLinks.css"

export enum IconColor {
  White,
  Black
}
interface ContactLinksProps {
  iconColor: IconColor;
  animate?: boolean;
}

export const ContactLinks = ({ iconColor, animate = false }: ContactLinksProps): JSX.Element => {
  console.log(window.screen.width);
  if (animate) {
    return (
      <div className="contactLinksBody" >
        <AnimatedIcon icon={<LinkedInSVG iconColor={iconColor} />} delay={0} />
        <AnimatedIcon icon={<EmailSVG iconColor={iconColor} />} delay={0.25} />
        <AnimatedIcon icon={<LocationSVG iconColor={iconColor} />} delay={0.5} />
      </div >
    )
  } else {
    return (
      <div className="contactLinksBody" >
        <LinkedInSVG iconColor={iconColor} />
        <EmailSVG iconColor={iconColor} />
        <LocationSVG iconColor={iconColor} />
      </div>
    )
  }
}

interface AnimatedIconProps {
  icon: ReactNode;
  delay: number;
}

function AnimatedIcon({ icon, delay }: AnimatedIconProps) {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.8, delay: 4 + delay }}
    >
      {icon}
    </motion.div>
  )
}

function LinkedInSVG({ iconColor }: ContactLinksProps): JSX.Element {
  return (
    <a href="https://www.linkedin.com/in/kyle-m-h/" target="_blank" rel="noreferrer">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={iconColor === IconColor.White ? "white" : "grey"} viewBox="0 0 16 16">
        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
      </svg>
    </a>
  )
}

function EmailSVG({ iconColor }: ContactLinksProps): JSX.Element {
  return (
    <a href="mailto:contact@kyle-herrmann.ca" target="_blank" rel="noreferrer">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={iconColor === IconColor.White ? "white" : "grey"} viewBox="0 0 16 16">
        <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2H2Zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671Z" />
        <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034v.21Zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z" />
      </svg>
    </a>

  )
}

function LocationSVG({ iconColor }: ContactLinksProps): JSX.Element {
  return (
    <a href="http://maps.google.com/?q=Vancouver" target="_blank" rel="noreferrer">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={iconColor === IconColor.White ? "white" : "grey"} viewBox="0 0 16 16">
        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
      </svg>
    </a>
  )
}
