
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { ContactLinks, IconColor } from '../ContactLinks/ContactLinks';
import "./ContactModal.css"

interface ContactModalProps {
  showContactModal: boolean;
  setShowContactModal: (toggle: boolean) => void;
}

export const ContactModal = ({ showContactModal, setShowContactModal }: ContactModalProps) => {
  return (
    <div className="contactModal">
      <Modal size="lg" centered show={showContactModal} onHide={() => setShowContactModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Me</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>I'm currently working remotely from Vancouver, BC, and available outside of PST working hours.</p>
          <p>For any inquiries, please email me for a timely response!</p>
          <a href="mailto:contact@kyle-herrmann.ca">contact@kyle-herrmann.ca</a>

        </Modal.Body>
        <Modal.Footer>
          <div className='contactModalFooter'>
            <ContactLinks iconColor={IconColor.Black} />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
