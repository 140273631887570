import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "./App.css"
import { TopNavigation, InfoPanel, ContactLinks, ContactModal } from "./components"
import { PanelView } from './components/InfoPanel';
import { IconColor } from './components/ContactLinks';
import { TypeAnimation } from 'react-type-animation';

// gcloud app deploy

function App() {
  const [showPanel, setShowPanel] = useState<boolean>(false)
  const [panelView, setPanelView] = useState<PanelView>(PanelView.WorkHistory);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  return (
    <>
      <TopNavigation setShowPanel={setShowPanel} setPanelView={setPanelView} setShowContactModal={setShowContactModal} />
      <div className="page">
        <div className="welcome">
          <TypeAnimation
            sequence={[
              'Kyle Herrmann',
              1000,
            ]}
            wrapper="span"
            speed={50}
            className='mainPageHeader'
            cursor={false}
          />
           <TypeAnimation
            sequence={[
              '', // Start empty
              1500,
              'Full Stack Engineer | Solutions Consultant',
            ]}
            wrapper="span"
            speed={50}
            className='mainPageDescriptor'
            cursor={false}
          />
          <br />
          <ContactLinks iconColor={IconColor.White} animate={true} />
        </div>
        <InfoPanel showPanel={showPanel} setShowPanel={setShowPanel} setPanelView={setPanelView} panelView={panelView} />
        <ContactModal showContactModal={showContactModal} setShowContactModal={setShowContactModal} />
      </div >
    </>
  );
}


export default App;
