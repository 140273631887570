
import React, { ReactNode, useState } from "react"
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image'
import "./InfoPanel.css"

export enum PanelView {
  WorkHistory,
  Skills,
}

interface WorkHistoryDrawerProps {
  showPanel: boolean;
  setShowPanel: (toggle: boolean) => void;
  panelView: PanelView;
  setPanelView: (view: PanelView) => void;
}

export const InfoPanel = ({ showPanel, setShowPanel, panelView = PanelView.WorkHistory, setPanelView }: WorkHistoryDrawerProps): JSX.Element => {
  return (
    <Offcanvas show={showPanel} onHide={() => setShowPanel(false)} >
      <Nav variant="tabs" defaultActiveKey="/home">
        <Nav.Item>
          <Nav.Link onClick={() => setPanelView(PanelView.WorkHistory)}>Work History</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link onClick={() => setPanelView(PanelView.Skills)}>Skills</Nav.Link>
        </Nav.Item>
      </Nav>

      <Offcanvas.Header closeButton>
        <h2>Employment 2020-2025</h2>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {panelView === PanelView.WorkHistory ? <div className="workHistoryBody"><WorkHistoryBody /></div> : <SkillsBody />}
      </Offcanvas.Body>
    </Offcanvas>

  )
}

function WorkHistoryBody() {
  return (
    <div className="workHistoryPane">
      <Card>
        <Card.Body>
          <div className="workTitle" onClick={() => window.open("https://getjobber.com/")}>
            <Card.Title>Jobber</Card.Title>
            <Image src="https://storage.googleapis.com/kherrmann_app_resources/jobber_logo.svg" />
          </div>
          <p><b className="workHistoryHeading">Software Engineer | Full Time | January 2022 – Present</b></p>
          <ul>
            <li>Developed and maintained reporting software for a high-traffic web application, enabling realtime data visualization and analysis.</li>
            <li>Collaborated with tech leads across the company to implement a decorator pattern, significantly improving report performance by enabling preloading of nested queries.</li>
            <li>Utilized Rockset query lambdas to efficiently aggregate and analyze large data sets, consisting of millions of records.</li>
            <li>Developed and maintained report services, presenters, and corresponding types and resolvers, leveraging ActiveRecord and GraphQL to query data.</li>
            <li>Developed a React front-end that consumed aggregate data for a seamless user experience.</li>
            <li>Demonstrated technical leadership by leading a POC that successfully implemented the Nivo library for visualizing complex job-costing data through React.</li>
            <li>Led the implementation of a POC that rebuilt the export feature in reports, enhancing data export capabilities, cutting load times in half, and improving user experience.</li>
            <li>Played a key role in maintaining comprehensive documentation outlining best practices for writing Jest tests, ensuring code quality and test coverage.</li>
            <li>Actively contributing to the public Atlantis codebase, utilizing centralized patterns and components to streamline development processes and promote code reusability.</li>
            <li>Facilitated daily scrum meetings, fostering effective communication and collaboration among team members to ensure project milestones were met.</li>
            <li>Exhibited dedication and commitment by actively participating in resolving technical incidents outside of business hours, such as restoring the functionality of an API responsible for visualizations on the landing page.</li>
            <li>Successfully refactored the expenses portion of the mobile codebase, transitioning from Ember to React Native as part of a company-wide project, improving performance, maintainability, and development efficiency.</li>
          </ul>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <div className="workTitle" onClick={() => window.open("https://www.elantis.com/")}>
            <Card.Title>Elantis Solutions Inc</Card.Title>
            <Image src="https://storage.googleapis.com/kherrmann_app_resources/elantis.svg" />
          </div>
          <p><b className="workHistoryHeading">Software Solutions Specialist | Full-Time | July 2020 – January 2022</b></p>
          <ul>
            <li>Technical lead of a project that migrated a client from SharePoint 2007 to SharePoint Online, integrating a custom document management application with Office365.</li>
            <li>Developed and deployed custom web parts using SPFx (SharePoint Framework).</li>
            <li>Created PowerShell scripts leveraging PnP and ShareGate to restructure and migrate data at the library level.</li>
            <li>Assisted in the architectural design of the SharePoint Online tenant.</li>
            <li>Contributed to the development and deployment of an API enabling SharePoint data entry and retrieval.</li>
            <li>Successfully migrated workflows from SharePoint 2007 to Power Automate (formerly known as Microsoft Flow).</li>
            <li>Served as a technical lead on multiple projects involving data migration and restructuring:</li>
            <span className="secondaryPoint">
              <li>Developed and executed scripts to provision team sites and migrate source files from document sets to their corresponding libraries.</li>
              <li>Implemented scripts to migrate a client from SharePoint 2013 to SharePoint Online, modernize content, and perform site configurations.</li>
              <li>Assisted in the architectural design of SharePoint Online tenant environments.</li>
            </span>
            <li>Wrote and maintained a collection of PowerShell scripts utilized company-wide as a centralized migration strategy.</li>
            <span className="secondaryPoint">
              <li>Utilized a modular approach to configure tenant-level settings, deploy and configure sites, and migrate content.</li>
            </span>
            <li>Automated business processes using the Power Platform.</li>
            <span className="secondaryPoint">
              <li>Led the automation of a "Request for Destruction" procedure, which included dynamic approvals, cascading views, and an integrated inquiry chat.</li>
              <li>Led the automation of an Incident Management procedure, including closed end-user intake forms and an admin center with integrated Power BI reporting.</li>
              <li>Developed monthly intake forms for clients.</li>
            </span>
            <li>Deployed SharePoint environments and performed server updates regularly.</li>
          </ul>
        </Card.Body>
      </Card>
    </div>
  )
}

function SkillsBody(): JSX.Element {
  const [toggleSkillsModal, setToggleSkillsModal] = useState<boolean>(false)
  const [toggleHandsOnExperience, setToggleHandsOnExperience] = useState<boolean>(false)
  const [toggleProjectsModal, setToggleProjectsModal] = useState<boolean>(false)
  return (
    <div className="skillsPane">
      <div className="skillsPaneRow">
        <BuildCard
          bodyText={"Technologies I've worked with frequently throughout my career."}
          titleText={"Technical Proficiencies"}
          imageUrl={"https://storage.googleapis.com/kherrmann_app_resources/braces-asterisk.svg"}
          setToggleModalView={setToggleSkillsModal}
        />
        <BuildCard
          bodyText={"Technologies I've worked with directly on large-scale solutions."}
          titleText={"Hands-on Experience"}
          imageUrl={"https://storage.googleapis.com/kherrmann_app_resources/gear.svg"}
          setToggleModalView={setToggleHandsOnExperience}
        />
      </div>
      <div className="skillsPaneRow">
        <BuildCard
          bodyText={"Personal development."}
          titleText={"Academic and Personal Projects"}
          imageUrl={"https://storage.googleapis.com/kherrmann_app_resources/book.svg"}
          setToggleModalView={setToggleProjectsModal}
        />
      </div>
      <BuildModal cardTitle={"Technical Proficiencies"} setToggleModalView={setToggleSkillsModal} toggleModalView={toggleSkillsModal}>
        <TechnicalSkillsCard />
      </BuildModal>
      <BuildModal cardTitle={"Hands-on Experience"} setToggleModalView={setToggleHandsOnExperience} toggleModalView={toggleHandsOnExperience}>
        <HandsOnSkillsCard />
      </BuildModal>
      <BuildModal cardTitle={"Academic and Personal Projects"} setToggleModalView={setToggleProjectsModal} toggleModalView={toggleProjectsModal}>
        <PersonalProjectsCard />
      </BuildModal>
    </div>
  )
}


interface BuildCardProps {
  bodyText: string;
  titleText: string;
  imageUrl: string;
  setToggleModalView: (toggle: boolean) => void;
}

function BuildCard({ bodyText, titleText, imageUrl, setToggleModalView }: BuildCardProps): JSX.Element {
  return (
    <div className="skillsCard">
      <Card style={{ width: '18rem' }} onClick={() => setToggleModalView(true)}>
        <div className="skillsCardBody">
          <Card.Img variant="top" src={imageUrl} />
          <Card.Body>
            <Card.Title>{titleText}</Card.Title>
            <Card.Text>
              {bodyText}
            </Card.Text>
          </Card.Body>
        </div>
      </Card>
    </div>
  )
}

interface BuildModalProps {
  cardTitle: string;
  setToggleModalView: (toggle: boolean) => void;
  toggleModalView: boolean;
  children: ReactNode | ReactNode[];
}

function BuildModal({ cardTitle, setToggleModalView, toggleModalView, children }: BuildModalProps): JSX.Element {
  return (
    <Modal show={toggleModalView} onHide={() => setToggleModalView(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>{cardTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  )
}

function TechnicalSkillsCard(): JSX.Element {
  return (
    <div className="skillsModalBody">
      <ListGroup variant="flush">
        <ListGroup.Item>React/React Native</ListGroup.Item>
        <ListGroup.Item>TypeScript/JavaScript</ListGroup.Item>
        <ListGroup.Item>GraphQL Ruby</ListGroup.Item>
        <ListGroup.Item>Ruby on Rails</ListGroup.Item>
        <ListGroup.Item>Jest/RSpec</ListGroup.Item>
        <ListGroup.Item>HTML/CSS/Bootstrap</ListGroup.Item>
        <ListGroup.Item>Java and JavaFX</ListGroup.Item>
        <ListGroup.Item>SharePoint development</ListGroup.Item>
        <ListGroup.Item>Office365</ListGroup.Item>
        <ListGroup.Item>PowerShell</ListGroup.Item>
        <ListGroup.Item>Power Platform</ListGroup.Item>
        <ListGroup.Item>Robotic Process Automation</ListGroup.Item>
      </ListGroup>
    </div>
  )
}

function HandsOnSkillsCard(): JSX.Element {
  return (
    <ListGroup variant="flush">
      <ListGroup.Item>Docker</ListGroup.Item>
      <ListGroup.Item>C, C#</ListGroup.Item>
      <ListGroup.Item>SQL</ListGroup.Item>
      <ListGroup.Item>SPFx</ListGroup.Item>
      <ListGroup.Item>Azure</ListGroup.Item>
      <ListGroup.Item>Nintex</ListGroup.Item>
      <ListGroup.Item></ListGroup.Item>
    </ListGroup>
  )
}

function PersonalProjectsCard(): JSX.Element {
  return (
    <div className="skillsModalBody">
      <ListGroup variant="flush">
        <ListGroup.Item>3D chess game written in JavaFX</ListGroup.Item>
        <ListGroup.Item>3D arcade space shooter written in Swift</ListGroup.Item>
        <ListGroup.Item>“Car Cost Calculator” Web Application</ListGroup.Item>
      </ListGroup>
    </div>
  )
}
